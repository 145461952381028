import React from "react";

import SimpleForm from './SimpleForm';

const App = () => (
  <div>
    <SimpleForm />
  </div>
);

export default App;
